<template>
    <div class="auth-wrapper d-flex no-block justify-content-center align-items-center" style="background-color: #9FCCC7">
        <div style="position: fixed;bottom: 0;left: 0; z-index: 1;">
            <img src="../../assets/img/cs.png" alt="" style="width: 40%;">
        </div>
        <div class="auth-box" style="z-index: 2;">
            <div id="loginform">
                <div class="logo">
                    <span class="db"><img src="../../assets/img/logo-icon.png" class=" mb-3" alt="logo" style="width: 45px;"/></span>

                    <h5 class="font-medium ">{{ $t("apps.app_name") }}</h5>
                    <p>
                        {{ $t("auth.sign_in_as") }} 
                        <span class="text-primary">
                            {{ $t("global.customer_service") }}
                        </span>
                    </p>
                </div>
                <!-- Form -->
                <div class="row">
                    <div class="col-12">
                        <form class="form-horizontal m-t-20" id="loginform" method="POST" v-on:submit.prevent="signIn">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon1"><i class="ti-user"></i></span>
                                </div>
                                <input type="text" class="form-control form-control-lg" :placeholder="$t('auth.username_or_email')" v-model="form.email" aria-label="Username" aria-describedby="basic-addon1">
                            </div>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon2"><i class="ti-pencil"></i></span>
                                </div>
                                <input class="form-control form-control-lg" :placeholder="$t('auth.password')" :type="passwordFieldType" v-model="form.password" required aria-label="Password" aria-describedby="basic-addon1">
                            </div>
                            <div class="form-group row">
                                <div class="col-md-12">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="customCheck1">
                                        <label class="custom-control-label" for="customCheck1">Remember me</label>
                                        <router-link to="/reset-password" id="to-recover" class="text-dark float-right"><i class="fa fa-lock m-r-5"></i> {{ $t("auth.forgot_password") }}</router-link>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group text-center">
                                <div class="col-xs-12 p-b-20">
                                    <button class="btn btn-block btn-lg btn-info d-flex justify-content-center" style="gap: .5rem" :disabled="isLoading">
                                        <div v-if="isLoading" class="spinner-border spinner" role="status"></div>
                                        <div>{{ $t("auth.sign_in") }}</div>
                                    </button>
                                </div>
                            </div>

                            <hr>
                            
                            <div class="form-group text-center">
                                <div class="col-xs-12 p-b-20">
                                    <router-link to="/login" class="btn btn-block btn-lg btn-primary d-flex justify-content-center" style="gap: .5rem">
                                        <div>Masuk sebagai {{ $t("global.admin") }}</div>
                                    </router-link>
                                </div>
                            </div>
                            
                            
                            <div class="form-group m-b-0 m-t-10">
                                <div class="col-sm-12 text-center">
                                    <!-- {{ $t("auth.doesnot_have_account") }}
                                    <router-link to="/register" class="color-primary">
                                        {{ $t("auth.register_now") }}
                                    </router-link>
                                    <br />
                                    {{ $t("auth.need_verify_account") }}
                                    <router-link to="/verify" class="color-primary">
                                        {{ $t("auth.verify_now") }}
                                    </router-link> -->
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div id="recoverform">
                <div class="logo">
                    <span class="db"><img src="../../assets/images/logo-icon.png" alt="logo" /></span>
                    <h5 class="font-medium m-b-20">Recover Password</h5>
                    <span>Enter your Email and instructions will be sent to you!</span>
                </div>
                <div class="row m-t-20">
                    <!-- Form -->
                    <form class="col-12" action="index.html">
                        <!-- email -->
                        <div class="form-group row">
                            <div class="col-12">
                                <input class="form-control form-control-lg" type="email" required="" placeholder="Username">
                            </div>
                        </div>
                        <!-- pwd -->
                        <div class="row m-t-20">
                            <div class="col-12">
                                <button class="btn btn-block btn-lg btn-danger" type="submit" name="action">Reset</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {},
        data() {
            return {
                form: {
                    email: "",
                    password: "",
                },
                passwordFieldType: "password",
                isLoading: false,
            };
        },
        mounted() {},
        methods: {
            async signIn() {
                this.isLoading = true;

                try {
                    await this.$store.dispatch("auth_login_cs", this.form);
                    await this.$root.$emit("login", true);
                    //this.$toast.success("Login success");
                    await this.$router.push("/cs-page/web-whatsapp");
                    window.location.reload()
                } catch (err) {
                    this.$toast.error(err.response.data.message);
                }
                this.isLoading = false;
            },
            togglePassword() {
                if (this.passwordFieldType == "password") {
                    this.passwordFieldType = "text"
                } else {
                    this.passwordFieldType = "password"
                }
            }
        },
    };
</script>

<style scoped>
    .spinner {
        height: 1rem !important;
        width: 1rem !important;
    }

    .btn {
        line-height: 1rem !important;
    }
</style>