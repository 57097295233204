<template>
    <div class="auth-wrapper d-flex no-block justify-content-center align-items-center" style="background:url('../../auth-bg.jpg') no-repeat center center; background-color: #F0F5F9">
        <div class="auth-box">
            <div id="loginform">
                <div class="logo">
                    <span class="db"><img src="../../assets/img/logo-icon.png" class=" mb-3" alt="logo" style="width: 45px;"/></span>

                    <h5 class="font-medium ">{{ $t("apps.app_name") }}</h5>
                    <p>
                        {{ $t("auth.reset_passowrd") }}
                    </p>
                </div>
                <!-- Form -->
                <div class="row">
                    <div v-if="page === 'reset'" class="col-12">
                        <form class="form-horizontal m-t-20" id="loginform" method="POST" v-on:submit.prevent="resetPassword">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon1"><i class="ti-user"></i></span>
                                </div>
                                <input type="text" class="form-control form-control-lg" :placeholder="$t('global.email')" v-model="form.email" aria-label="Username" aria-describedby="basic-addon1">
                            </div>
                            <div class="form-group text-center">
                                <div class="col-xs-12 p-b-20">
                                    <button class="btn btn-block btn-lg btn-info d-flex justify-content-center" style="gap: .5rem" :disabled="isLoading">
                                        <div v-if="isLoading" class="spinner-border spinner" role="status"></div>
                                        <div>{{ $t("auth.reset_passowrd") }}</div>
                                    </button>
                                </div>
                            </div>
                            <div class="form-group m-b-0 m-t-10">
                                <div class="col-sm-12 text-center">
                                    <!-- Don't have an account? <a href="authentication-register1.html" class="text-info m-l-5"><b>Sign Up</b></a> -->
                                    {{ $t("auth.already_have_account") }}
                                    <router-link to="/login" class="color-primary">
                                        {{ $t("auth.sign_in_now") }}
                                    </router-link>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div v-if="page === 'verify'" class="col-12">
                        <form class="form-horizontal m-t-20" id="loginform" method="POST" v-on:submit.prevent="verifyPassword">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon1"><i class="ti-lock"></i></span>
                                </div>
                                <input type="text" class="form-control form-control-lg" placeholder="Masukan OTP" v-model="verifyForm.otp" aria-label="Masukan OTP" aria-describedby="basic-addon1">
                            </div>
                            <div class="form-group text-center">
                                <div class="col-xs-12 p-b-20">
                                    <button class="btn btn-block btn-lg btn-info d-flex justify-content-center" style="gap: .5rem" :disabled="isLoading">
                                        <div v-if="isLoading" class="spinner-border spinner" role="status"></div>
                                        <div>Verifikasi OTP</div>
                                    </button>
                                </div>
                            </div>
                            <div class="form-group m-b-0 m-t-10">
                                <div class="col-sm-12 text-center">
                                    <!-- Don't have an account? <a href="authentication-register1.html" class="text-info m-l-5"><b>Sign Up</b></a> -->
                                    {{ $t("auth.already_have_account") }}
                                    <router-link to="/login" class="color-primary">
                                        {{ $t("auth.sign_in_now") }}
                                    </router-link>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div v-if="page === 'change-password'" class="col-12">
                        <form class="form-horizontal m-t-20" id="loginform" method="POST" v-on:submit.prevent="changePassword">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon1"><i class="ti-pencil"></i></span>
                                </div>
                                <input type="text" class="form-control form-control-lg" placeholder="Masukan Kata Sandi" v-model="changeForm.password" aria-label="Masukan Kata Sandi" aria-describedby="basic-addon1">
                            </div>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon1"><i class="ti-pencil"></i></span>
                                </div>
                                <input type="text" class="form-control form-control-lg" placeholder="Masukan Konfirmasi Kata Sandi" v-model="changeForm.confirm_password" aria-label="Masukan Konfirmasi Kata Sandi" aria-describedby="basic-addon1">
                            </div>
                            <div class="form-group text-center">
                                <div class="col-xs-12 p-b-20">
                                    <button class="btn btn-block btn-lg btn-info d-flex justify-content-center" style="gap: .5rem" :disabled="isLoading">
                                        <div v-if="isLoading" class="spinner-border spinner" role="status"></div>
                                        <div>Reset Kata Sandi</div>
                                    </button>
                                </div>
                            </div>
                            <div class="form-group m-b-0 m-t-10">
                                <div class="col-sm-12 text-center">
                                    <!-- Don't have an account? <a href="authentication-register1.html" class="text-info m-l-5"><b>Sign Up</b></a> -->
                                    {{ $t("auth.already_have_account") }}
                                    <router-link to="/login" class="color-primary">
                                        {{ $t("auth.sign_in_now") }}
                                    </router-link>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {},
        data() {
            return {
                form: {
                    email: "",
                    phone: "",
                },
                verifyForm: {
                    otp: "",
                },
                changeForm: {
                    password: "",
                    confirm_password: "",
                },
                isLoading: false,
                page: "reset", //reset verify change-password
            };
        },
        mounted() {
        },
        methods: {
            async resetPassword() {
                this.isLoading = true;
                // Validation
                if (!this.form.email) {
                    this.$toast.error("Email tidak boleh kosong");
                    this.isLoading = false;
                    return;
                }
                try {
                    let verify = await this.$store.dispatch("auth_reset_password", this.form);
                    this.$toast.success(verify.data.message);
                    // await this.$router.push("/verify-reset-password");

                    this.page = "verify";
                } catch (err) {
                    this.$toast.error(err.response.data.message);
                }
                this.isLoading = false;
            },
            async verifyPassword() {
                this.isLoading = true;
                // Validaiton
                if (!this.verifyForm.otp) {
                    this.$toast.error("OTP tidak boleh kosong");
                    this.isLoading = false;
                    return;
                }
                try {
                    let verify = await this.$store.dispatch("auth_verify_reset_password", {
                        email: this.form.email,
                        otp: this.verifyForm.otp,
                    });
                    this.$toast.success(verify.data.message);

                    this.page = "change-password";
                } catch (err) {
                    this.$toast.error(err.response.data.message);
                }
                this.isLoading = false;
            },
            async changePassword() {
                this.isLoading = true;
                // Validation
                if (this.changeForm.password !== this.changeForm.confirm_password) {
                    this.$toast.error("Kata sandi tidak sama");
                    this.isLoading = false;
                    return;
                }
                try {
                    let verify = await this.$store.dispatch("auth_change_reset_password", {
                        email: this.form.email,
                        otp: this.verifyForm.otp,
                        password: this.changeForm.password,
                    });
                    this.$toast.success(verify.data.message);
                    await this.$router.push("/login");

                    this.page = "verify";
                } catch (err) {
                    this.$toast.error(err.response.data.message);
                }
                this.isLoading = false;
            },
        },
    };
</script>

<style scoped>
    .spinner {
        height: 1rem !important;
        width: 1rem !important;
    }

    .btn {
        line-height: 1rem !important;
    }
</style>